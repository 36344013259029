import React from 'react';
//import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import isound from '../../images/alianzasImages/Isound.png';
import globant from '../../images/alianzasImages/Globant.png';
import voices from '../../images/alianzasImages/TVH.png';
import cedar from '../../images/alianzasImages/Cedar.png';
import inovation from '../../images/alianzasImages/innovation.png';
import instagram from '../../images/logosRedes/instagram.png';
import facebook from '../../images/logosRedes/facebook.png';
import youtube from '../../images/logosRedes/youtube.png';

const useStyles = makeStyles(theme => ({
    section : {
      [theme.breakpoints.up('sm')]: { 
        paddingTop: '10px',
        '& h3': {
          color: 'white'
        }
    },
      paddingTop: '10px',
      fontFamily: 'Barlow Semi Condensed',
      textAlign: 'center'
    },
    alianzas: {
      backgroundColor: '#491061',
      paddingBottom: '50px'
    },
    mainFooter: {
      backgroundColor: '#662382',
      overflow:'hidden'
    },
    tituloAlianza: {
      color: '#f6a808',
      fontFamily: 'Barlow Semi Condensed',
      padding: '20px',
      fontWeight: '900',
      fontSize: '30px',
      marginTop: '0',
      marginBottom: '0'
    },
    alianzasLogos: {
      '& img': {
        width: '100px',
        height: '100px',
      },
      '& h3':{
        color: 'white'
      },
      display: 'inline-block',
      marginRight: '20px',
      marginLeft: '20px'
    },
    tituloWhats: {
        fontFamily: 'Barlow Semi Condensed',
        padding: '10px',
        fontWeight: '200',
        fontSize: '18px',
        color: 'white',
        [theme.breakpoints.up('sm')]: { 
          fontSize: '30px',
        }
    },
    tituloMail: {
        fontFamily: 'Barlow Semi Condensed',
        padding: '15px',
        fontWeight: '200',
        fontSize: '15px',
        color: 'white',
        [theme.breakpoints.up('sm')]: { 
          fontSize: '20px',
        }
        
    },
    tituloAddress: {
      fontFamily: 'Barlow Semi Condensed',
      padding: '20px',
      fontWeight: '200',
      fontSize: '30px',
      color: 'white',
      display: 'block'
    },
    map: {
      width: '100%',
      paddingTop: '20px',
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
      justifyContent: 'center'
    },
    map1: {
      width: '95%',
      display: 'inline-block',
      [theme.breakpoints.up('sm')]: { 
        width: '40%',
      }
    },
    map2: {
      width: '95%',
      verticalAlign: 'top',
      display: 'inline-block',
      [theme.breakpoints.up('sm')]: { 
        width: '40%',
      },
      '& h1': {
        margin: '0',
        fontSize: '20px',
        padding: '16px',
        [theme.breakpoints.up('sm')]: { 
          fontSize: '25px',
        }
      }
    },
    footerLegal: {
      paddingTop: '60px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingBottom: '60px',
      color: 'white'
    },
    redes: {
      textAlign: 'center',
      '& img': {
        display: 'inline-block',
        width: '80px',
        padding: '20px',
      },
      '& .facebook': {
          filter: 'invert(100%)'
      }
    }
}));

const Footer = (props) => {
  const classes = useStyles();
 
    return (
        <> 
            <section id="footer" className={classes.section}>
              <div className={classes.alianzas}>
                <h1 className={classes.tituloAlianza}>ALIANZAS</h1>
                <div className={classes.alianzasLogos}>
                <img src={isound}/>
                <h3>I sound</h3>
                </div>
                <div className={classes.alianzasLogos}>
                <img src={globant}/>
                <h3>Globant</h3>
                </div>
                <div className={classes.alianzasLogos}>
                <img src={voices}/>
                <h3>The Voices</h3>
                </div>
                <div className={classes.alianzasLogos}>
                <img src={cedar}/>
                <h3>Cedar</h3>
                </div>
                <div className={classes.alianzasLogos}>
                <img src={inovation}/>
                <h3>Inovation</h3>
                </div>
              </div>
              <div className={classes.mainFooter}>
                <h1 className={classes.tituloAlianza}>HORARIO:<span className={classes.tituloWhats}><br></br> Lunes a Sábado de 7 a.m a 7 p.m</span></h1>
                <h2 className={classes.tituloWhats}>Celular/Whatsapp ● (+57) 300 461 6226</h2>
                <div className={classes.map}>
                    <div className={classes.map1}>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.535214751312!2d-74.04894048460447!3d4.676613143166572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9a91e57bae6d%3A0x19e8e95e3053af6a!2sMinota%20music%20academy!5e0!3m2!1ses!2sco!4v1615607548047!5m2!1ses!2sco" width="100%" height="450" allowFullScreen="" loading="lazy"></iframe>
                      <span className={classes.tituloAddress}>Cra.11 No.93b - 31 Local 6</span>
                    </div>
                    <div className={classes.map1}>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d674.325304881907!2d-74.03500479895945!3d4.726971239574944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f85d0e0f10925%3A0xa3bcce4d2273f9d5!2sMiNota%20Music%20Cedritos!5e0!3m2!1ses-419!2sco!4v1740182549602!5m2!1ses-419!2sco" width="100%" height="450" allowfullscreen="" loading="lazy"></iframe>
                      <span className={classes.tituloAddress}>Cra.12 No.147 - 36 </span>
                    </div>
                </div>
                <div className={classes.map2}>
                  <h1 className={classes.tituloAlianza}>Información general, Dirección, Convenios: <br/><span className={classes.tituloMail}> minotamusic@gmail.com</span> <br/>(Aillen Arciniegas)</h1>
                </div>
                <div className={classes.redes}>
                  <a onClick={()=> window.open("https://www.instagram.com/minotamusicacademy/", "_blank")}>
                     <img src={instagram} /></a>
                  <a onClick={()=> window.open("https://www.facebook.com/minotamusicacademy", "_blank")}>
                     <img src={facebook} className="facebook"/></a>
                  <a onClick={()=> window.open("https://www.youtube.com/channel/UCxOGhyC7jH-oPdsZX-Z9d9Q", "_blank")}>
                      <img src={youtube}/></a>
                </div>
                <div className={classes.footerLegal}>
                  Copyright@ 2021 MiNota Music Academy. All rights reserved. The MiNota Music Academy name and the MiNota Music Academy logo are registered trademarks.
                </div>
              </div>
            </section>
       
        </>
     );
};
 
export default Footer;