import React from 'react';
import Home from './components/Home';
import Login from "./components/login/login";
import Curso from "./components/curso/curso";
import ClientesPotenciales from './components/clientesPotenciales/ClientesPotenciales';
import './App.css';
import PageNew from './components/pageWorking/pageNew';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import LandingContainer from './components/landingPages/LandingContainer1';
import Felicitaciones from './components/landingPages/components/Felicitaciones';

function App() {
  
  return (
      <>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path= "/felicitaciones">
            <Felicitaciones />
          </Route> 
          <Route path="/pagos">
            <PageNew tipo={"pago"}/>
          </Route>
          <Route path="/login"> 
            <Login/>
          </Route>
          <Route path="/clientes-potenciales"> 
            <ClientesPotenciales />
          </Route>
          <Route path="/curso">
            <Curso/>
          </Route>
          <Route path="/galeria">
            <PageNew tipo={"galeria"}/>
          </Route>
          <Route path="/contenido-virtual">
            <PageNew tipo={"contenido virtual"}/>
          </Route>
          {//El contador solo podra funcionar bien para dias positivos y no funcionara conteos mayores a 99 dias 
          // El conteo se debe setear en unix time con una ruta parecida a esta 
          //
          }
          <Route path="/promo/:id" children={<LandingContainer />} />
          <Route path="/" >
            <Home />
          </Route>
        </Switch>
      </>
  );
}

export default App;
