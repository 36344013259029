import React from 'react';
import { useState } from 'react';
//import logo from './minotavirtual.png';
import './Home.css';
import WhatsApp from './WhatsApp';
import WhatsAppModal from './WhatsAppModal.jsx';
import TemporaryDrawer from './DrawerFormularioClaseGratis';
import CustomizedDialogs from './ModalClaseGratis';
import SuperiorBar from './SuperiorBarTop';
import BannerLogo from './BannerLogo';
import MainHeader from './header/Header';
import FirstSection from './section1/FirstSection';
import SecondSection from './section2/SecondSection';
import Testimonios from './testimonios/Testimonios';
import Intrumentos from './instrumentos/Instrumentos';
import Planes from './planes/Planes';
import Footer from './footer/Footer';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from "@material-ui/core/styles";
import {
  Link
} from "react-router-dom";
const homePageStyle = theme => ({
    root: {
        '& a': {
            fontFamily: 'Barlow Semi Condensed',
            color: 'white',
            textDecoration: 'none'
        },
        fontFamily: 'Barlow Semi Condensed',   
    },
    selected: {
        backgroundColor: "#3ebdb1 !important",
        color: "white",
        fontWeight: 600,
        fontFamily: 'Barlow Semi Condensed',
    }
    });

function Home(props) {
  const [showModalWhatsApp, setShowModalWhatsApp] = useState(false);
  const [openModalClaseGratis, setOpenModalClaseGratis] = useState(false);
  const [openFormularioClaseGratis, setOpenFormularioClaseGratis] = useState(false);
  const [scrollOn, setScrollOn] =useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { classes } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  window.onscroll = function() {
    growShrinkLogo();
  };

  
  const growShrinkLogo = () => {
          if (document.body.scrollTop > 5 || document.documentElement.scrollTop > 5) {
            setScrollOn(true);
          } else {
            setScrollOn(false);
          }
  };
  
  const showModalWhats = () => {
    setShowModalWhatsApp(true);
  };

  const closeModalWhats = () => {
    setShowModalWhatsApp(false);
  };

  const handleCloseClaseGratis = () => {
    setOpenModalClaseGratis(false);
  };

  const handleOpenFormularioClaseGratis = () => {
      setOpenFormularioClaseGratis(true);
  };

  const handleCloseFormularioClaseGratis = () => {
    setOpenFormularioClaseGratis(false);
    setOpenModalClaseGratis(false);
    };
  const MenuPrincipal = 
        <div className="menu-principal" >
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MenuIcon />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                     marginLeft: '-50px',
                     backgroundColor: '#662383',
                     color: 'white',
                     fontFamily: 'Barlow Semi Condensed',
                    }
                   }}
            >
                <MenuItem selected classes={{ root: classes.root, selected: classes.selected }}  onClick={handleClose}>
                    <Link to="/">Home</Link>
                </MenuItem>
                <MenuItem classes={{ root: classes.root, selected: classes.selected }}  onClick={handleClose}>
                    <a href="#cursos">Cursos</a>
                </MenuItem>
                <MenuItem classes={{ root: classes.root, selected: classes.selected }} onClick={handleClose}>
                    <a href="#planes-precios">Planes / Precios</a>
                </MenuItem>
                <MenuItem classes={{ root: classes.root, selected: classes.selected }} onClick={handleClose}>
                    <Link to="/contenido-virtual">Contenido virtual</Link>
                </MenuItem>
                <MenuItem classes={{ root: classes.root, selected: classes.selected }} onClick={handleClose}>
                    <Link to="/pagos">Boton de Pagos</Link>
                </MenuItem>
                <MenuItem classes={{ root: classes.root, selected: classes.selected }} onClick={handleClose}>
                    <Link to="/galeria">Galeria</Link>
                </MenuItem>
                <MenuItem classes={{ root: classes.root, selected: classes.selected }} onClick={handleClose}>
                    <a href="#preguntas">Preguntas Frequentes</a>
                </MenuItem>
            </Menu>
        </div>;
  React.useEffect(() => {
    setTimeout( () => {
        setOpenModalClaseGratis(true);
    }, 15000);
  },[]);
  return (
    <div className="App">
      <div className="firstMainPart">
        <MainHeader></MainHeader>
        <FirstSection></FirstSection>
      </div>
      <SecondSection></SecondSection>
      <BannerLogo onScroll={scrollOn}/>
      {MenuPrincipal}
      <SuperiorBar/>
      <Testimonios/>
      <Intrumentos/>
      {/* <Planes/> */}
      <Footer/>
      {/* Aca cierra el contenido de la pagina */}
      <CustomizedDialogs open={openModalClaseGratis && !openFormularioClaseGratis} handleClose={handleCloseClaseGratis} openFormulario={handleOpenFormularioClaseGratis}/>
      <TemporaryDrawer state={openFormularioClaseGratis} open={handleOpenFormularioClaseGratis} onClose={handleCloseFormularioClaseGratis}/>
      {!showModalWhatsApp && <div className="whattsapp" onClick={showModalWhats}><WhatsApp /></div>} 
      {showModalWhatsApp && <WhatsAppModal onClickClose={closeModalWhats}/>}     
    </div>
  );
}

export default withStyles(homePageStyle)(Home)
