import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, Timestamp } from 'firebase/firestore/lite';
import './ClientesPotenciales.css';
import Imgenlogo from '../../images/logoMinotaNegro.png';
function ClientesPotenciales(props) {
   const [clientList, setClientes] = useState([]);
   useEffect(()=>{
    getClientes(db);
   },[]);

   var config = {
    apiKey: "AIzaSyCkIDcNumm96sb5sUqnqPgsTfDhh3NYzyw",
      authDomain: "minotaacademy2019.firebaseapp.com",
      databaseURL: "https://minotaacademy2019.firebaseio.com",
      projectId: "minotaacademy2019",
      storageBucket: "minotaacademy2019.appspot.com",
      messagingSenderId: "861408473010"
    };
  
  const app = initializeApp(config);
  const db = getFirestore(app);
    
    async function getClientes(db) {
      const clientes = collection(db, 'NewClients-campanaPadres');
      const clientesSnapshot = await getDocs(clientes);
      const clientsList = clientesSnapshot.docs.map(doc => { 
        console.log(doc.data());
        setClientes(prev => [...prev, doc.data()]);
      });
      return clientsList;
    }
    const data = clientList.sort(function(a, b){return b.fecha.seconds - a.fecha.seconds});

    return (
        <div className="container-cp">
            <div className="img-cp">
                <img src={Imgenlogo} alt="logo minota" />
            </div>
            <h1>Clientes Potenciales Minota</h1>
            <div className="row-cp">
                <div className="column1-cp">Nombre</div>
                <div className="column2-cp">Correo</div>
                <div className="column3-cp">Celular</div>
                <div className="column4-cp">Fecha</div>
                <div className="column5-cp">Campaña</div>
            </div>
            {
                clientList.map((cliente, index) => {
                    return (
                        <div className="row-cp" key={index}>
                            <div className="column1-cp">{cliente?.nombre}</div>
                            <div className="column2-cp">{cliente?.email}</div>
                            <div className="column3-cp">{cliente?.celular}</div>
                            <div className="column4-cp">{(new Date(cliente?.fecha.seconds * 1000)).toDateString()}</div>
                            <div className="column5-cp"> {cliente?.campana?? "LP-1"}</div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default ClientesPotenciales;