import React from 'react';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { purple, indigo } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
    gratis: {
        animation: `$rigthToLeft 20000ms infinite`,
        animationTimingFunction: 'linear',
        //width: '200px',
        display: 'inline-block',
    },
    '@keyframes rigthToLeft': {
        '0%': {
            transform: 'translate(40%)'
        },
        '100%': {
            transform: 'translateX(-39%)'
        }
    },
    gratis1: {
        animation: `$rigthToLeft1 20000ms infinite`,
        animationTimingFunction: 'linear',
        //width: '200px',
        display: 'inline-block',
    },
    '@keyframes rigthToLeft1': {
        '0%': {
            transform: 'translateX(43%)'
        },
        '100%': {
            transform: 'translateX(-36%)'
        }
    },
    formContainer: {
        height: '100%',
    },
    formGoogle: {
            height: '100%',
            '& iframe': {
                height: '100%',
            }
    },
    arrowDown: {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        animation: `$bounce 2000ms infinite`,
    },
    '@keyframes bounce': {
        '0%, 20%, 50%, 80%, 100%': {
          transform: 'translateY(0)'
        },
        '40%': {
          transform: 'translateY(-30px)',
        },
        '60%': {
          transform: 'translateY(-15px)'
        }
    },
    closeButton: {
        position: 'absolute',
        left: '-5px',
        top: '20px',
        backgroundColor: 'white'
    },
    drawer: {
        '& .MuiPaper-root': {
            overflow: 'visible',
            '& button': {
                filter: 'drop-shadow( 3px 3px 4px rgba(0, 0, 0, .7))',
            }, 
            '& .MuiIconButton-root:hover': {    
                backgroundColor: 'rgba(0, 0, 0, .7)'
            }
        }     
    }

}));

const TemporaryDrawer = (props) => {
    const classes = useStyles();
    const [arrowFormDown, setArrowFormDown] = React.useState(true);
    const isUpSmallBreakPoint = isWidthUp('sm', props.width);
    let widthForm = isUpSmallBreakPoint ? "500" : "350";
    if (window.innerWidth<330) { widthForm = "320"; }
    React.useEffect(() => {
        setTimeout( () => setArrowFormDown(false), 30000);
    },[]);

    const handleOnClose = () => {
        props.onClose();
        setArrowFormDown(true);
    };

    const list = (anchor) => (
        <div
        className={clsx(classes.formContainer)}
        role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className={classes.formGoogle}>
                <iframe id="frameNew" title='Formulario' src="https://docs.google.com/forms/d/e/1FAIpQLSebWGSXw7uc8zq2IdM889PjtnncskLIF9rw1Olsw6Q-uQTQow/viewform?pli=1"  width={widthForm} ></iframe>
            </div>
        </div>
    );

    const ColorButton = withStyles((theme) => ({
        root: {
          overflowX: 'hidden',
          color: theme.palette.getContrastText('#f8aa00'),
          zIndex: '99',
          textTransform: 'inherit',
          '& span': {
            [theme.breakpoints.up('sm')]: { 
                display: 'inherit',
                fontSize: '20px'    
            },
            display: 'inline-block',
            textAlign: 'right',
            fontSize: '10px',
          },     
          backgroundColor: '#f8aa00',
          '&:hover': {
            backgroundColor: purple[700],
            '& .gratis': {
                    color: 'yellow',
            }
          },
          '& span:hover': {
            color: 'white'
          },
          '& .gratis': {
            color: indigo[900],
            [theme.breakpoints.up('sm')]: { 
                fontSize: '16px'
            },
             whiteSpace: 'nowrap',
             overflow: 'hidden',
          },
          width:  '100%',
          transform: 'rotate(0deg)',
          '-ms-transform':'rotate(0deg)', /* IE 9 */
          '-moz-transform':'rotate(0deg)', /* Firefox */
          '-webkit-transform':'rotate(0deg)', /* Safari and Chrome */
          '-o-transform':'rotate(0deg)',
          position: 'fixed',
          left:'0px',
          top: '25px' /* Oper */
        },
      }))(Button);

    return ( 
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <ColorButton onClick={props.open} variant="contained" color="primary">
                        
                        <div className={classes.gratis}>
                            <span className={`gratis`}>
                                <strong>AGENDA AQUÍ &nbsp; </strong>Tu clase de prueba &nbsp; <strong> GRATIS</strong> &nbsp; 😜
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <strong>AGENDA AQUÍ &nbsp; </strong>Tu clase de prueba &nbsp; <strong> GRATIS</strong> &nbsp; 😜
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <strong>AGENDA AQUÍ &nbsp; </strong>Tu clase de prueba &nbsp; <strong> GRATIS</strong> &nbsp; 😜
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <strong>AGENDA AQUÍ &nbsp; </strong>Tu clase de prueba &nbsp; <strong> GRATIS</strong> &nbsp; 😜
                            </span>
                        </div>
                        {isUpSmallBreakPoint && <div className={classes.gratis1}>
                            <span className={`gratis`}>
                                <strong>AGENDA AQUÍ &nbsp; </strong>Tu clase de prueba &nbsp; <strong> GRATIS</strong> &nbsp; 😜
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <strong>AGENDA AQUÍ &nbsp; </strong>Tu clase de prueba &nbsp; <strong> GRATIS</strong> &nbsp; 😜
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <strong>AGENDA AQUÍ &nbsp; </strong>Tu clase de prueba &nbsp; <strong> GRATIS</strong> &nbsp; 😜
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <strong>AGENDA AQUÍ &nbsp; </strong>Tu clase de prueba &nbsp; <strong> GRATIS</strong> &nbsp; 😜
                            </span>
                        </div>}
                    </ColorButton>
                    <Drawer className={classes.drawer} anchor={anchor} open={props.state} onClose={handleOnClose}>
                        {arrowFormDown &&
                            <ArrowDownwardIcon className={classes.arrowDown} color="primary" fontSize="large"/>
                        }
                        {list(anchor)}
                        <IconButton onClick={handleOnClose} className={classes.closeButton}>
                            <CloseRoundedIcon color="primary"  ></CloseRoundedIcon>
                        </IconButton>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}

export default withWidth()(TemporaryDrawer);