import React from 'react';
import { makeStyles } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const colorGreenWhatsApp = '#25D366';
const useStyles = makeStyles({
    box: {
        position: 'fixed',
        zIndex: '99',
        width: '95%',
        maxWidth: '400px',
        minHeight: '170px',
        maxHeight: '350px',
        borderRadius: '32px',
        boxShadow: '0 2px 6px 0 rgba(0,0,0,.5)',
        textAlign: 'left',
        background: 'white',
        overflow: 'hidden', 
        bottom: '20px',
        right: '2.5%',
        '& .joinchat_header': {
            position: 'relative',
            flexShrink: '0',
            height: '55px',
            padding: '0 26px',
            margin: '0',
            justifyContent: 'space-between',
            background: colorGreenWhatsApp,
            textAlign: 'left',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            '& h5': {
                paddingLeft: '4px'
            }    
        },
        '& .joinchat_message': {
            position: 'relative',
            minHeight: '60px',
            padding: '17px 20px',
            margin: '17px 26px 5px',
            borderRadius: '32px',
            background: '#fff',
            color: '#4a4a4a',
            filter: 'drop-shadow(0 1px 2px rgba(0,0,0,.3))',
            lineHeight: '1.4',
        },
        '& .joinchat_message:before': {
            content: '""',
            width: '0px',
            height: '0px',
            position: 'absolute',
            bottom: '14px',
            left: '-18px',
            borderLeft: '15px solid transparent',
            borderRight: '15px solid white',
            borderTop: '15px solid #ffffff',
            borderBottom: '15px solid transparent'
        },
        '& .joinchat_action': {
            display: 'flex',
            padding: '10px',
            justifyContent: 'flex-end'
        },
    },
    messagingContainer: {
        maxHeight: '210px',
        minHeight: 'auto',
        overflow: 'scroll',
    },
    containerTitleModal: {
        display: 'flex'
    }
});

const WhatsAppModal = (props) => {
    const classes = useStyles();
    const isUpSmallBreakPoint = isWidthUp('sm', props.width);
    const button = {
        borderRadius: 35,
        backgroundColor: colorGreenWhatsApp,
        padding: "15px 30px",
        fontSize: "18px",
        color: "white"
    };
    const goToWhatsApp = () => {
        window.location.href='https://wa.link/507dly';
    };
    const message = isUpSmallBreakPoint ? <React.Fragment>
        Hola <span role="img" aria-label="smile">😊</span>{"   !!! "}Hemos detectado que nos miras desde un pc <span role="img" aria-label="pc">💻</span> {", "} si tienes abierto Whatsapp-Web da click en el boton de abajo, sino agreganos a tu celular <span role="img" aria-label="phone">📱</span> directamente a este numero <strong>+57 3004616226.</strong>, y te daremos toda la informacion que desees
        </React.Fragment> : <React.Fragment>Hola <span role="img" aria-label="smile">😊</span>{"   !!! "} Hemos detectado nos hablas desde un celular <span role="img" aria-label="phone">📱</span>, hablemos por Whatsapp es mucho mas facil y rapido</React.Fragment>;
    
    return (<div className={classes.box}>
                <div className="joinchat_header">
                    <div className={classes.containerTitleModal}>
                        <WhatsAppIcon fontSize="large"/><Typography variant="h5">WhatsApp</Typography>                
                    </div>     
                    <IconButton onClick={props.onClickClose}><CloseIcon/></IconButton>                                
                </div>
                <div className={classes.messagingContainer}>
                    <div className="joinchat_message">{message}</div>
                </div>
                <div className="joinchat_action">
                    <Button
                        variant="contained"
                        style={button}
                        endIcon={<SendIcon style={{ fontSize: 30 }}/>}
                        onClick={goToWhatsApp}
                    >
                        Abrir chat
                    </Button>
                </div>      
            </div> )
}
 
export default withWidth()(WhatsAppModal);